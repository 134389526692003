import imgProximus from "../assets/img/projets/Docdrop/img_projet1.JPG";
import imgProximusContactPage from "../assets/img/projets/Docdrop/contact_page.png";
import imgProximusPersonnalizedAttachements from "../assets/img/projets/Docdrop/personnalizedAttachements.png";
import imgProximusPersonnalizedAttachements2 from "../assets/img/projets/Docdrop/personnalizedAttachements_2.png";

import imgHomeHope from "../assets/img/projets/Hope/login.png";
import imgHomeHope2 from "../assets/img/projets/Hope/inscription.png";
import imgHomeHope3 from "../assets/img/projets/Hope/form_diseases.png";

import privateLease from "../assets/img/projets/PrivateLease/Peugeot_Newsletter.png";

import imgWLC from "../assets/img/projets/WLC/detailsembedded.png";
import imgWLCCarousel from "../assets/img/projets/WLC/carouselembedded.png";

import img3Pommes from "../assets/img/projets/3Pommes/Page1.png";
import img3Pommes2 from "../assets/img/projets/3Pommes/Page2.png";
import img3Pommes3 from "../assets/img/projets/3Pommes/Page3.png";
import img3Pommes4 from "../assets/img/projets/3Pommes/Page4.png";

import VisuelAnneDeSmet from "../assets/img/projets/AnneDeSmet/Visuel_AnneDeSmet1.jpg";
import VisuelAnneDeSmet2 from "../assets/img/projets/AnneDeSmet/VisuelAnneDeSmet2.jpg";
import VisuelPsyplurielles from "../assets/img/projets/Psyplurielles/Page.png";

import imgHomeMetis from "../assets/img/projets/Metis/home_metis.JPG";
import compteutilisateur from "../assets/img/projets/Metis/compteutilisateur.png";
import listcontact from "../assets/img/projets/Metis/listcontact.png";
import meds from "../assets/img/projets/Metis/meds.png";
import profil from "../assets/img/projets/Metis/profil.png";
import profilcontact from "../assets/img/projets/Metis/profilcontact.png";

import pageintermarche1 from "../assets/img/projets/Page_Intermarché/ITM-ACTIONEPARGNE-FOOT-V2.png";

import newsletterIntermarche1 from "../assets/img/projets/Newsletters_Intermarché/Sem29-NEWSLETTERFRV1.png";
import newsletterIntermarche2 from "../assets/img/projets/Newsletters_Intermarché/Sem26-NEWSLETTERFRV4.png";
import newsletterIntermarche3 from "../assets/img/projets/Newsletters_Intermarché/Sem27-NEWSLETTERFRV10.jpg";
import newsletterIntermarche4 from "../assets/img/projets/Newsletters_Intermarché/Sem28-NEWSLETTERFRV6.jpg";

import Page1HerveeHasquin from "../assets/img/projets/HerveeHasquin/Accueil.png";
import Page2HerveeHasquin from "../assets/img/projets/HerveeHasquin/Biographie.png";

import Page1EbRemorques from "../assets/img/projets/EbRemorques_PagesWeb/EbRemorques_Acceuil.png";
import Page2EbRemorques from "../assets/img/projets/EbRemorques_PagesWeb/EbRemorques_Contact.png";
import Page3EbRemorques from "../assets/img/projets/EbRemorques_PagesWeb/Location_EB_Remorques.jpg";
import Page4EbRemorques from "../assets/img/projets/EbRemorques_PagesWeb/Panier_EB_Remorques.png";

let professionalItems = [
  {
    id: 0,
    image: [
      imgHomeMetis,
      compteutilisateur,
      listcontact,
      profilcontact,
      profil,
      meds,
    ],
    alt: imgHomeMetis,
    titleFR: "Métis",
    titleEN: "Métis",
    overline: "Projet professionels",
    descriptionFR:
      "J'ai eu l'opportunité de développer une application full stack en utilisant React 18, Symfony 6 et MySQL pour l'association 'Infirmiers de Rue'. Cette association aide les sans-abris à sortir de leur situation difficile, et mon application leur permet de répertorier leurs médicaments, les lieux visités et les personnes rencontrées pour mieux organiser leur travail. En plus de ces fonctionnalités principales, j'ai également créé des pages 'contact' et 'lieux' ainsi qu'une page utilisateur pour les administrateurs. Cette dernière permet de gérer les comptes des utilisateurs en changeant leurs mots de passe, leurs e-mails et leurs noms d'utilisateur. C'était une expérience très enrichissante pour moi en tant que développeuse full stack, car j'ai pu mettre en pratique mes compétences en utilisant différents outils et technologies pour créer une application complète et utile pour une cause importante. Je suis fière du résultat final et j'espère que cette application sera utile pour l'association 'Infirmiers de Rue'.",
    descriptionEN:
      "I had the opportunity to develop a full-stack application using React 18, Symfony 6, and MySQL for the 'Infirmiers de Rue' association. This organization helps homeless individuals improve their difficult situation, and my application allows them to record their medications, visited locations, and interactions with people to better organize their work. In addition to these main features, I also created 'contact' and 'locations' pages, as well as a user page for administrators. The latter allows managing user accounts by changing their passwords, emails, and usernames. It was a very enriching experience for me as a full-stack developer, as I could apply my skills using different tools and technologies to create a comprehensive and useful application for an important cause. I am proud of the final result, and I hope this application will be beneficial for the 'Infirmiers de Rue' association.",

    link: "liendescription",
    techList: ["React", "Symfony"],
  },

  {
    id: 1,
    image: [imgHomeHope, imgHomeHope2, imgHomeHope3],
    alt: imgHomeHope,
    titleFR: "Hope",
    titleEN: "Hope",
    overline: "Projet professionels",
    descriptionFR:
      "Lors de l'une de mes missions chez Positive Thinking Company, j'ai dû intégrer une application en React sur un site internet appelé 'Cure Wiki', qui utilisait la plateforme WordPress. Mon rôle dans ce projet consistait à créer plusieurs formulaires en React 17, tout en relevant le défi de les intégrer parfaitement avec le site existant. L'un des principaux défis auxquels j'ai été confronté a été de travailler avec une API externe de la société. Cela a nécessité une compréhension approfondie de la structure de l'API et une communication étroite avec l'équipe de développement de l'entreprise pour s'assurer que tout était correctement intégré. En outre, j'ai travaillé sur la récupération de nombreuses informations en front-end pour les envoyer via plusieurs requêtes en back-end. Cela a été un défi complexe, car cela impliquait de créer des mécanismes de communication entre les différentes parties du site pour garantir que tout fonctionnait de manière transparente. Enfin, j'ai également travaillé sur la mise en place d'une fonctionnalité d'auto-complétion pour toutes les maladies existantes. J'ai réussi à la rendre très sensible à ce que l'utilisateur tape et à la configurer pour qu'elle ne s'ouvre que lorsque l'utilisateur a commencé à taper, ce qui a contribué à une expérience utilisateur plus fluide et plus intuitive. Ce projet a été un défi stimulant, mais j'ai réussi à livrer un produit final qui a parfaitement répondu aux exigences et aux attentes de l'entreprise.",
    descriptionEN:
      "During one of my missions at Positive Thinking Company, I had to integrate a React application into a website called 'Cure Wiki,' using the WordPress platform. My role was to create multiple React 17 forms and seamlessly integrate them with the existing site. One major challenge was working with the company's external API, requiring a deep understanding of its structure and close collaboration with the development team to ensure smooth integration. I also worked on retrieving frontend information for multiple backend requests, a complex task involving communication between different site parts for seamless functionality. Additionally, I implemented an auto-complete feature for all existing diseases, making it highly responsive and appearing only when the user started typing, enhancing the user experience. Despite the challenging nature of the project, I successfully delivered a final product that met the company's requirements and expectations.",

    link: "liendescription",
    techList: ["React", "Wordpress"],
  },

  {
    id: 2,
    image: [imgWLC, imgWLCCarousel],
    alt: imgWLC,
    titleFR: "BNP",
    titleEN: "BNP",
    overline: "Projet professionels",
    descriptionFR:
      "Pour ce projet, j'ai travaillé en tant que front-end developer pour l'un des clients d'une des missions que j'ai effectuée chez 'Positive Thinking Company'. Ma mission consistait entre autre à redessiner l'iframe situé au milieu de la page pour une application de redistribution de tickets. Les fondateurs de l'application ont exigé un nouveau design pour répondre aux besoins de leur client. La tâche n'était pas facile car l'application dans l'iframe était très complexe et volumineuse. Cela a nécessité beaucoup de temps et d'efforts pour comprendre les subtilités de React et Typescript, mais j'ai trouvé cela très intéressant. En fin de compte, j'ai réussi à créer un design entièrement nouveau pour l'iframe, qui a satisfait les exigences du client.",
    descriptionEN:
      "During one of my assignments at Positive Thinking Company, I had to integrate a React application on a website called 'Cure Wiki', which used the WordPress platform. My role in this project was to create multiple forms in React 17, while facing the challenge of seamlessly integrating them with the existing site. One of the main challenges I faced was working with an external API from the company. This required a deep understanding of the API's structure and close communication with the company's development team to ensure everything was properly integrated. Additionally, I worked on retrieving various information on the front end to send via multiple requests on the back end. This was a complex challenge, as it involved creating communication mechanisms between different parts of the site to ensure everything worked seamlessly. Finally, I also worked on implementing an autocomplete feature for all existing diseases. I managed to make it very responsive to what the user types and configured it to only open when the user has started typing, contributing to a smoother and more intuitive user experience. This project was a stimulating challenge, but I succeeded in delivering a final product that perfectly met the company's requirements and expectations.",
    link: "liendescription",
    techList: ["React", "Sass", "Context"],
  },
  {
    id: 3,
    image: [
      imgProximus,
      imgProximusContactPage,
      imgProximusPersonnalizedAttachements,
      imgProximusPersonnalizedAttachements2,
    ],
    alt: imgProximus,
    titleFR: "Docdrop Proximus",
    titleEN: "Docdrop Proximus",
    overline: "Projet professionels",
    descriptionFR:
      "J'ai eu la chance de travailler en étroite collaboration avec un développeur sénior sur ce projet. Le développeur sénior a commencé le projet et j'ai rejoint l'équipe pour travailler sur plusieurs modules, y compris la partie contact, les pièces jointes personnalisées et bien plus encore. En tant que développeur junior, j'ai été en mesure d'apprendre beaucoup de choses de mon collègue plus expérimenté tout en contribuant activement au projet. J'ai travaillé dur pour comprendre les exigences du projet et m'assurer que mes modules étaient en phase avec la vision globale du site. Le projet Proximus DocDrop a été une expérience passionnante pour moi, car j'ai pu travailler sur un projet complexe avec un développeur sénior et améliorer mes compétences en programmation. Je suis fier du travail que j'ai accompli pour ce projet et je suis convaincu qu'il témoigne de ma capacité à travailler efficacement en équipe pour produire des résultats de qualité.",

    descriptionEN:
      "I had the opportunity to work closely with a senior developer on this project. The senior developer initiated the project, and I joined the team to work on various modules, including the contact section, custom attachments, and much more. As a junior developer, I was able to learn a lot from my more experienced colleague while actively contributing to the project. I worked hard to understand the project requirements and ensure that my modules aligned with the overall vision of the site. The Proximus DocDrop project was an exciting experience for me as I had the chance to work on a complex project with a senior developer and enhance my programming skills. I take pride in the work I accomplished for this project, and I am confident that it reflects my ability to effectively collaborate in a team to deliver quality results.",
    link: "liendescription",
    techList: ["Angular", "Ionic", "Sass", "Orientée Objet"],
  },

  {
    id: 5,
    image: [Page1HerveeHasquin, Page2HerveeHasquin],
    alt: Page1HerveeHasquin,

    titleFR: "Hervé Hasquin Site",
    titleEN: "Hervé Hasquin Website",
    overline: "Projet professionels",
    descriptionFR:
      "C'est avec une grande fierté que je vous présente le site hervéhasquin.be, que j'ai eu l'opportunité de concevoir et de développer en collaboration avec l'ancien ministre Hervé Hasquin. Ce projet m'a permis de mettre en avant les réalisations remarquables de M. Hasquin au cours de sa carrière, en mettant l'accent sur ses réalisations, ses projets et son dernier livre 'Les œillères rouges'. En travaillant avec M. Hasquin, j'ai pu découvrir une personne passionnée par son travail et dévouée à partager ses connaissances et son expérience avec les autres. Ce projet a été une expérience enrichissante pour moi en tant que web designer et j'espère que le site sera une source d'inspiration pour les visiteurs en quête de découverte et de connaissance. ",

    descriptionEN:
      "It is with great pride that I present to you the website hervéhasquin.be, which I had the opportunity to design and develop in collaboration with former minister Hervé Hasquin. This project allowed me to showcase Mr. Hasquin's remarkable achievements throughout his career, emphasizing his accomplishments, projects, and his latest book 'Les œillères rouges' (The Red Blinders). Working with Mr. Hasquin, I discovered a person passionate about his work and dedicated to sharing his knowledge and experience with others. This project has been a rewarding experience for me as a web designer, and I hope the website serves as a source of inspiration for visitors seeking discovery and knowledge.",
    link: "liendescription",
    techList: ["Creative", "Wordpress", "Css"],
  },
  {
    id: 6,
    image: [img3Pommes2, img3Pommes3, img3Pommes4],
    alt: img3Pommes,
    titleFR: "3 Pommes",
    titleEN: "3 Pommes",
    overline: "Projet professionels",
    descriptionEN:
      "I am delighted to share my work as a freelance developer for ThinkPeaks, where I was tasked with building several pages for their React project. Using the Webpack compiler, I followed their path to create a consistent development environment. Working closely with their team, I successfully crafted React pages that perfectly met their requirements while ensuring optimal performance and responsiveness for users. I was particularly pleased with the quality of collaboration with ThinkPeaks, allowing me to work independently while maintaining constant communication to meet project deadlines and requirements. This project has been a rewarding experience for me as a freelance developer, and I hope to have the opportunity to work on similar projects in the future.",

    descriptionFR:
      "Je suis ravi de partager mon travail en tant que freelance pour la société ThinkPeaks, où j'ai été chargé de construire plusieurs pages pour leur projet en React. En utilisant le compilateur Webpack, j'ai suivi le même chemin qu'eux pour créer un environnement de développement cohérent. En travaillant en étroite collaboration avec leur équipe, j'ai réussi à créer des pages en React qui répondaient parfaitement à leurs besoins, tout en étant performantes et réactives pour les utilisateurs. J'ai été particulièrement satisfait de la qualité de la collaboration avec ThinkPeaks, qui m'a permis de travailler de manière autonome tout en assurant une communication constante pour respecter les délais et les exigences du projet. Ce projet a été une expérience enrichissante pour moi en tant que freelance, et j'espère avoir l'opportunité de travailler sur d'autres projets similaires dans le futur.",
    link: "liendescription",
    techList: ["React", "Webpack", "JSX", "Sass"],
  },

  {
    id: 7,
    image: [privateLease],
    alt: privateLease,
    titleFR: "Newsletters Private Lease",
    titleEN: "Newsletters Private Lease",
    overline: "Projet professionels",
    descriptionEN:
      "As a developer at Havas, I was tasked with creating an appealing and functional HTML and CSS newsletter for this project, ensuring compatibility across various email clients using the Litmus testing tool. I worked closely with the Havas team to understand Peugeot's needs and to craft a newsletter that reflected their brand image and values. Leveraging my HTML and CSS coding skills, I successfully created a newsletter that was both stylish and user-friendly for recipients. By using Litmus results to debug compatibility issues, I ensured the newsletter would be consistently displayed across a wide range of email clients. I was pleased with the final results of this project, enabling Peugeot to effectively communicate with its audience and reinforce its commitment to innovation and quality.",

    descriptionFR:
      "En tant que développeur chez Havas. Dans ce projet, j'ai été chargé de créer une newsletter HTML et CSS attrayante et fonctionnelle, tout en assurant une compatibilité avec différents clients de messagerie grâce à l'outil de test Litmus. J'ai travaillé en étroite collaboration avec l'équipe de Havas pour comprendre les besoins de Peugeot et pour créer une newsletter qui reflétait leur image de marque et leurs valeurs. Grâce à mes compétences en codage HTML et CSS, j'ai réussi à créer une newsletter à la fois élégante et facile à utiliser pour les destinataires. En utilisant les résultats de Litmus pour déboguer les problèmes de compatibilité, j'ai pu garantir que la newsletter serait présentée de manière cohérente sur une grande variété de clients de messagerie. J'ai été ravi des résultats finaux de ce projet, qui a permis à Peugeot de communiquer efficacement avec son public et de renforcer son engagement envers l'innovation et la qualité.",
    link: "liendescription",
    techList: ["Litmus", "Html", "Css"],
  },
  {
    id: 8,
    image: [VisuelAnneDeSmet, VisuelAnneDeSmet2],
    alt: VisuelAnneDeSmet,
    titleFR: "Anne De Smet",
    titleEN: "Anne De Smet",
    overline: "Projet professionels",
    descriptionFR:
      "Ce projet a été réalisé en utilisant Adobe Illustrator, et j'ai travaillé en étroite collaboration avec Aline de Smet pour créer une carte de visite professionnelle qui représente son métier de diététicienne. Nous avons échangé de nombreuses idées et nous avons travaillé ensemble pour créer un design qui reflète l'image professionnelle qu'elle souhaite véhiculer. Le défi de ce projet était de créer une carte de visite qui non seulement présente les coordonnées d'Aline de Smet, mais qui transmette également l'essence de son métier de diététicienne. J'ai donc choisi des couleurs et des images qui reflètent la santé et le bien-être, tout en présentant de manière claire et concise les informations importantes pour les patients d'Aline. Le résultat final est une carte de visite professionnelle, élégante et efficace, qui représente parfaitement le métier d'Aline de Smet en tant que diététicienne. Je suis fier de présenter ce projet dans mon portfolio et je suis convaincu que cela témoigne de mes compétences en tant que designer graphique.",

    descriptionEN:
      "This project was executed using Adobe Illustrator, and I collaborated closely with Aline de Smet to create a professional business card that represents her role as a dietitian. We exchanged numerous ideas and worked together to design something that reflects the professional image she wishes to convey. The challenge in this project was to create a business card that not only presents Aline de Smet's contact information but also communicates the essence of her role as a dietitian. I chose colors and images that reflect health and well-being, while presenting important information for Aline's patients in a clear and concise manner. The final result is a professional, elegant, and effective business card that perfectly represents Aline de Smet's profession as a dietitian. I take pride in featuring this project in my portfolio, and I am confident that it demonstrates my skills as a graphic designer.",
    link: "liendescription",
    techList: ["Illustrator"],
  },

  {
    id: 9,
    image: [VisuelPsyplurielles],
    alt: VisuelPsyplurielles,
    titleFR: "Psyplurielles",
    titleEN: "Psyplurielles",
    overline: "Projet professionels",
    descriptionFR:
      "Ce site web a été construit en utilisant des langages de programmation natives tels que HTML, CSS et JavaScript. J'ai travaillé en étroite collaboration avec ma sœur, qui a conçu le design du site, pour créer une plateforme en ligne élégante et facile à naviguer pour Vanessa, une psychologue et coach de vie. En travaillant ensemble, nous avons mis en place une approche complémentaire où ma sœur a conçu l'aspect visuel du site tandis que je me suis concentré sur le développement des fonctionnalités et de la structure du site. Nous avons collaboré étroitement avec Vanessa pour comprendre ses besoins en matière de design et de fonctionnalités pour son site web.",

    descriptionEN:
      "This website was built using native programming languages such as HTML, CSS, and JavaScript. I collaborated closely with my sister, who designed the website, to create an elegant and user-friendly online platform for Vanessa, a psychologist and life coach. Working together, we established a complementary approach where my sister designed the visual aspect of the site while I focused on developing the features and structure. We collaborated closely with Vanessa to understand her design and functionality needs for the website.",
    link: "liendescription",
    techList: ["Javascript", "Sass", "Html"],
  },
  {
    id: 10,
    image: [pageintermarche1],
    alt: pageintermarche1,
    titleFR: "Intermarché",
    titleEN: "Intermarché",
    overline: "Projet professionels",
    descriptionEN:
      "I had the opportunity to work on an exciting project for Intermarché, a major food product distributor in France. The challenge was to redesign an entire page of their website for the FIFA World Cup period, highlighting their savings promotion. This project allowed me to showcase creativity and web design skills to create a unique visual that would capture the attention of the brand's customers.",

    descriptionFR:
      "J'ai eu l'opportunité de travailler sur un projet passionnant pour Intermarché, un important distributeur de produits alimentaires en France. Le défi était de redesigner une page entière de leur site web pour la période de la coupe du monde de football, en mettant en avant leur offre d'action épargne. Ce projet m'a permis de faire preuve de créativité et de compétences en design web pour créer un visuel unique qui capterait l'attention des clients de la marque. ",
    link: "liendescription",
    techList: ["Sketch", "Design", "Creative"],
  },
  {
    id: 11,
    image: [
      newsletterIntermarche1,
      newsletterIntermarche2,
      newsletterIntermarche3,
      newsletterIntermarche4,
    ],
    alt: newsletterIntermarche1,
    titleFR: "Intermarché newsletters",
    titleEN: "Intermarché newsletters",
    overline: "Projet professionels",
    descriptionFR:
      "Je suis heureuse de vous présenter mon travail en matière de newsletters pour la société Intermarché. Dans ce projet, j'ai utilisé mes compétences en design et en marketing pour créer des newsletters accrocheuses, engageantes et efficaces. Pour chaque newsletter, j'ai créé un visuel attrayant à l'aide de Photoshop, qui a été intégré par Campaign Monitor pour assurer une présentation professionnelle et cohérente. L'objectif était de donner envie aux utilisateurs de découvrir le contenu de la newsletter en scollant vers le bas. J'ai pris un grand plaisir à travailler sur ce projet, en combinant mes compétences en design et en marketing pour obtenir des résultats tangibles pour l'entreprise.",

    descriptionEN:
      "I am pleased to present my work on newsletters for Intermarché. In this project, I utilized my design and marketing skills to create captivating, engaging, and effective newsletters. For each newsletter, I crafted an appealing visual using Photoshop, which was seamlessly integrated by Campaign Monitor to ensure a professional and consistent presentation. The goal was to entice users to explore the newsletter content by scrolling down. I thoroughly enjoyed working on this project, combining my design and marketing skills to deliver tangible results for the company.",
    link: "liendescription",
    techList: ["Creative", "Photoshop", "Campaign Monitor"],
  },

  {
    id: 12,
    image: [
      Page1EbRemorques,
      Page2EbRemorques,
      Page3EbRemorques,
      Page4EbRemorques,
    ],
    alt: Page1EbRemorques,
    titleFR: "Eb Remorques Site",
    titleEN: "Eb Remorques Website",
    overline: "Projet professionels",
    descriptionEN:
      "I am delighted to present one of my first professional projects, the Ebremorques website. This e-commerce site specialized in the sale of trailers and accessories, underwent a complete redesign with a new look and enhanced features. Using a WordPress theme, I was able to create a more visually appealing and user-friendly site for visitors while improving e-commerce functionalities using PHP. Although the site is no longer available today, I take pride in sharing this project as it was highly appreciated by my employer and marked a significant milestone in my professional journey as a web developer.",

    descriptionFR:
      "Je suis ravi de présenter l'un de mes premiers projets professionnels, le site Ebremorques. Ce site web était un site de e-commerce spécialisé dans la vente de remorques et accessoires, qui a été entièrement repensé avec un nouveau design et des fonctionnalités améliorées. En utilisant un thème WordPress, j'ai pu créer un site plus attrayant et facile à naviguer pour les visiteurs, tout en améliorant les fonctionnalités de commerce électronique en utilisant PHP. Bien que le site ne soit plus disponible aujourd'hui, je suis fier de partager ce projet car il a été très apprécié par mon patron et a constitué une étape importante dans mon parcours professionnel en tant que développeur web. ",
    link: "liendescription",
    techList: ["Creative", "Wordpress", "Php"],
  },
];

export default professionalItems;
