import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.scss";
import "./index.css";
import "animate.css";
import Index from "./components/index/index.js";
import About from "./components/about/about.js";
import Contact from "./components/contact/contact.js";
import MenuSidebar from "./components/menu-sidebar/menu-sidebar.js";
import Projects from "./components/projects/projects.js";
import ItemProject from "./components/item-project/item-project.js";
import DropdownSwitcherLanguage from "./components/UI/dropdown-languages.js";

function App() {
  return (
    <div className="App">
      <DropdownSwitcherLanguage></DropdownSwitcherLanguage>
      <MenuSidebar></MenuSidebar>
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route exact path="about" element={<About />} />
        <Route exact path="skills" element={<Projects />} />
        <Route exact path="skills/:id" element={<ItemProject />} />
        {/* <Route exact path="contact" element={<Contact />}></Route> */}
      </Routes>
    </div>
  );
}

export default App;
