import React, { useEffect, useRef } from "react";
import gsap, { Power3 } from "gsap";
import "./index.scss";
import meLogo from "../../assets/img/mee_vector.png";

import { useTranslation } from "react-i18next";

export default function Index() {
  const textRef = useRef(); // Référence pour le texte
  const logoRef = useRef(); // Référence pour le logo
  const { t } = useTranslation();

  useEffect(() => {
    gsap.from(textRef.current, 1, {
      opacity: 0,
      y: 30,
      stagger: 0.25,
      ease: Power3.easeInOut,
    });

    gsap.from(logoRef.current, 1, {
      opacity: 0,
      y: 30,
      stagger: 0.25,
      ease: Power3.easeInOut,
    });
  }, []);

  return (
    <>
      <div className="layout-indexPage">
        <div className="layout-content">
          <div className="col-content">
            <div className="infos-index" ref={textRef}>
              <h3>{t("greeting")}</h3>
              <h1>
                <span>Caroline</span>
                <span id="lastName" className="lastName">
                  van Aerschot
                </span>
              </h1>

              <h3 className="d-flex underline-title">
                {t("jobTitle")}
                <span className="ml-1 icon-hand animate__animated animate__fadeInDownBig">
                  &#128075;
                </span>
              </h3>
            </div>
          </div>

          <div className="col-logo">
            <div className="img-index" ref={logoRef}>
              <img src={meLogo} alt="logo" className="logo-index" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
