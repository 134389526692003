import React from "react";
import ReactDOM from "react-dom";
import App from "./App.js";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { LangProvider } from "../src/context/LangContext.js";
ReactDOM.render(
  <LangProvider>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </LangProvider>,

  document.getElementById("root")
);
