import React from "react";
import Logo from "../../assets/img/img/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import {
  faHome,
  faUser,
  faScrewdriverWrench,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink } from "react-router-dom";
import "./menu-sidebar.scss";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const NavLinkStyled = styled(NavLink)`
  &::after {
    content: "${(props) => props.translatedcontent}";
  }
`;
const MenuSidebar = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="nav-bar">
        <Link className="logo" to="/">
          <img src={Logo} alt="Logo" />
        </Link>
        <nav className="nav-content">
          <NavLinkStyled
            exact="true"
            activeclassname="active"
            to="/"
            translatedcontent={t("linkHome")}
          >
            <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
          </NavLinkStyled>

          <NavLinkStyled
            to="/about"
            translatedcontent={t("linkAbout")}
            activeclassname="active"
            className="about-link"
          >
            <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
          </NavLinkStyled>
          <NavLinkStyled
            activeclassname="active"
            className="skills-link"
            to="/skills"
            translatedcontent={t("linkProjects")}
          >
            <FontAwesomeIcon icon={faScrewdriverWrench} color="#4d4d4e" />
          </NavLinkStyled>
          {/* <NavLinkStyled
            activeclassname="active"
            className="skills-contact"
            to="/contact"
            translatedcontent={t("linkContact")}
          >
            <FontAwesomeIcon icon={faAddressBook} color="#4d4d4e" />
          </NavLinkStyled> */}
        </nav>

        <ul>
          <li>
            <a
              href="https://www.linkedin.com/in/caroline-van-aerschot-901721183/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} color="#b9b9b9" />
            </a>
          </li>
          <li>
            <a
              href="https://github.com/shania873"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faGithub} color="#b9b9b9" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MenuSidebar;
