import React from "react";
import { changeLanguage } from "../../i18n";
import { useLang } from "../../context/LangContext";
export default function DropdownSwitcherLanguage() {
  const { toggleLang } = useLang();

  function onChangeLang(e) {
    localStorage.setItem("lang", e.target.value);
    changeLanguage(e.target.value);
    toggleLang(e.target.value);
  }
  return (
    <div className="custom-select dropdown">
      <select
        onChange={(e) => onChangeLang(e)}
        defaultValue={localStorage.getItem("lang")}
      >
        <option value="en">EN</option>
        <option value="fr">FR</option>
      </select>
    </div>
  );
}
