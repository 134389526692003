import { React, useState, useEffect, useRef } from "react";
//import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import gsap, { Power3 } from "gsap";
// import { Power3 } from "gsap/all.js";

import logoCSS from "../../assets/img/icons_skills/css_icons.svg";
import logoJavascript from "../../assets/img/icons_skills/js_icons.svg";
import logoSASS from "../../assets/img/icons_skills/sass_icons.svg";
import logoGIT from "../../assets/img/icons_skills/git_icons.svg";
import logoPhp from "../../assets/img/icons_skills/php_icons.svg";
import logoWordpress from "../../assets/img/icons_skills/wordpress_icons.svg";
import logoMySQL from "../../assets/img/icons_skills/mysql_icons.svg";
import logoReact from "../../assets/img/icons_skills/react.svg";
import logoAngular from "../../assets/img/icons_skills/angular.svg";
import logoAngularJS from "../../assets/img/icons_skills/angularJS.svg";
import logoReading from "../../assets/img/icons_hobbies/reading_icons.svg";
import logoCinema from "../../assets/img/icons_hobbies/pen_icons.svg";
import logoPen from "../../assets/img/icons_hobbies/cinema_icons.svg";

import CarouselAlice from "../UI/carousel-alice.js";

import CVCaroFR from "../../assets/img/CV_Caroline_van_Aerschot_2023_French.pdf";
import CVCaroEN from "../../assets/img/CV_Caroline_van_Aerschot_2023_English.pdf";
import { useLang } from "../../context/LangContext.js";
import "./about.scss";
import { useTranslation } from "react-i18next";

export default function About(props) {
  const { t } = useTranslation();
  const { lang } = useLang();
  const itemsSkills = [
    <div className="item" data-value="9">
      <img src={logoReact} alt="React" />
      <h3>React</h3>
    </div>,
    <div className="item" data-value="2">
      <img src={logoJavascript} alt={logoJavascript} />
      <h3>Javascript</h3>
    </div>,
    <div className="item" data-value="3">
      <img src={logoSASS} alt={"Sass"} />
      <h3>Sass</h3>
    </div>,
    <div className="item" data-value="4">
      <img src={logoGIT} alt={logoGIT} />
      <h3>GIT</h3>
    </div>,
    <div className="item" data-value="6">
      <img src={logoPhp} alt={logoPhp} />
      <h3>Php</h3>
    </div>,
    <div className="item" data-value="7">
      <img src={logoWordpress} alt={logoWordpress} />
      <h3>Wordpress</h3>
    </div>,
    <div className="item" data-value="8">
      <img src={logoMySQL} alt={logoMySQL} />
      <h3>MySQL</h3>
    </div>,
    <div className="item" data-value="10">
      <img src={logoAngular} alt={logoAngular} />
      <h3>Angular</h3>
    </div>,
    <div className="item" data-value="11">
      <img src={logoAngularJS} alt={logoAngularJS} />
      <h3>AngularJS</h3>
    </div>,
    <div className="item" data-value="1">
      <img src={logoCSS} alt={logoCSS} />
      <h3>CSS</h3>
    </div>,
  ];

  const itemsHobbies = [
    <div className="item" data-value="1">
      <img src={logoReading} alt={logoReading} />
      <h3> {t("reading")}</h3>
    </div>,
    <div className="item" data-value="2">
      <img src={logoCinema} alt={logoCinema} />
      <h3>{t("drawing")}</h3>
    </div>,
    <div className="item" data-value="3">
      <img src={logoPen} alt={"Cinema"} />
      <h3>{t("cinema")}</h3>
    </div>,
  ];

  const [items, setItems] = useState(itemsSkills);
  const [isHobbies, setBooleanHobbies] = useState(false);
  const [isSkills, setBooleanSkills] = useState(true);
  const boxRef = useRef();

  useEffect(() => {
    gsap.from(boxRef.current, 1, {
      opacity: 0,
      y: 30,
      stagger: 0.25,
      ease: Power3.easeInOut,
    });
  }, []);

  const choiceHobbies = () => {
    setItems(itemsHobbies);
    setBooleanHobbies(true);
    setBooleanSkills(false);
  };

  const choiceSkills = () => {
    setItems(itemsSkills);
    setBooleanSkills(true);
    setBooleanHobbies(false);
  };

  return (
    <div className="layout-AboutPage">
      <div className="layout-content">
        <div className="col-content">
          <div className="item-intro">
            <h1 className="d-flex">
              {t("titleAbout")}
              <span className="ml-1 animate__animated animate__fadeInDownBig">
                &#128525;
              </span>
            </h1>

            <p>{t("descriptionAbout")}</p>

            <h3 className="mt-1 d-flex">
              <span className="hightlight">{t("currentlyToLearn")} </span>
              <span className="ml-1  animate__animated animate__fadeInDownBig">
                &#128296;
              </span>
            </h3>
            <div className="d-flex">
              <Splide
                aria-label="My Favorite Images"
                options={{
                  direction: "ttb",
                  type: "loop",
                  perPage: 1,
                  autoplay: true,
                  pauseOnHover: true,
                  pauseOnFocus: true,
                  duration: 1000,
                  cover: true,
                  height: "75px",
                  arrows: false,
                  pagination: false,
                }}
              >
                <SplideSlide>{t("slideGitHubActions")}</SplideSlide>
                <SplideSlide>{t("slideTestUnit")}</SplideSlide>
                <SplideSlide>{t("slideNode")}</SplideSlide>
              </Splide>
            </div>
          </div>
        </div>
        <div className="col-logo">
          <div className="item-development" ref={boxRef}>
            <h4>
              <span
                className="skills-link"
                onClick={choiceSkills}
                style={{ color: isSkills === true ? "#0dca7b" : "white" }}
              >
                {t("skills")}
              </span>
              <span
                className="hobby-link"
                onClick={choiceHobbies}
                style={{ color: isHobbies === true ? "#0dca7b" : "white" }}
              >
                {t("hobbies")}
              </span>
            </h4>

            <CarouselAlice items={items} />
          </div>
        </div>
      </div>
    </div>
  );
}
