import { React, useEffect, useState } from "react";
import professionalItems from "../../datas/data.js";
import "./projects.scss";
import { useLang } from "../../context/LangContext.js";
import { useTranslation } from "react-i18next";
export default function Portfolio() {
  const [isProffesional, setBooleanProffesional] = useState(true);
  const [isPersonal, setBooleanPersonal] = useState(false);
  const { t } = useTranslation();
  const { lang } = useLang();
  const choiceProffesional = () => {
    setBooleanProffesional(true);
    setBooleanPersonal(false);
  };

  const choicePersonnal = () => {
    setBooleanPersonal(true);
    setBooleanProffesional(false);
  };

  useEffect(() => {
    choicePersonnal();
    choiceProffesional();
  }, []);

  return (
    <div className="set-layout">
      <div className="layout-content">
        <div className="col-content"></div>
        <div className="col-logo"></div>
      </div>
      <div className=" set-portfolio">
        <h1 className="mb-1 d-flex">
          <span>{t("projets")}</span>
          <span className="ml-1 animate__animated animate__fadeInDownBig">
            &#128170;
          </span>
        </h1>
        <div>
          <span
            className="link-portfolio"
            onClick={choiceProffesional}
            style={{ color: isProffesional === true ? "#0dca7b" : "white" }}
          >
            {t("projetsProffesionels")}
          </span>
        </div>

        <div className="gallery">
          {isProffesional === true &&
            professionalItems.map((project, idx) => (
              <div className="projet-image" key={idx}>
                <a
                  href={window.location.origin + "/skills/" + project.id}
                  className="link-itemPortfolio"
                >
                  <div className="content-short">
                    <span>
                      {lang === "fr" ? project.titleFR : project.titleEN}
                    </span>
                    <div className="projet-skills">
                      {project.techList.map((skills, idx) => (
                        <span key={idx}>{skills}</span>
                      ))}
                    </div>
                  </div>
                  <img src={project.image[0]} alt={project.image[0]} />
                </a>
              </div>
            ))}
          {isPersonal === true && <></>}
        </div>
      </div>
    </div>
  );
}
