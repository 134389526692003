import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";

let langUsed = localStorage.getItem("lang")
  ? localStorage.getItem("lang")
  : "en";

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  returnObjects: true,
  returnedObjectHandler: true,
  lng: langUsed,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export const changeLanguage = (language) => {
  return i18n.changeLanguage(language);
};

export default i18n;
