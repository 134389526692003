import React, { useEffect, useRef, useState } from "react";
import gsap, { Power3 } from "gsap";
import { useParams, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import professionalItems from "../../datas/data.js";
import "./item-project.scss";
import FsLightbox from "fslightbox-react";
import { useLang } from "../../context/LangContext.js";
import { useTranslation } from "react-i18next";
export default function ItemProject() {
  const params = useParams();
  const boxRef = useRef();
  const { lang } = useLang();
  const { t } = useTranslation();
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  useEffect(() => {
    gsap.from(boxRef.current, 1, {
      opacity: 0,
      y: 30,
      stagger: 0.25,
      ease: Power3.easeInOut,
    });
  }, []);

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  return (
    <>
      <div className="set-layout">
        <div className="set-portfolio-details">
          {professionalItems.map((project) => (
            <div key={project.id}>
              {params && project && project.id.toString() === params.id && (
                <>
                  <div className="projet-content">
                    <NavLink exact="true" className="btn-back" to="/skills">
                      <FontAwesomeIcon icon={faArrowLeft} color="#0dca7b" />{" "}
                      <span>{t("linkBack")}</span>
                    </NavLink>
                    <p className="project-overline">
                      <span>{t("projetsProffesionels")}</span>
                    </p>

                    <h1 className="text-4xl tracking-tight font-extrabold text-white-900 sm:text-4xl md:text-4xl">
                      <span>
                        {lang === "fr" ? project.titleFR : project.titleEN}
                      </span>
                    </h1>
                    <div className="skills-set">
                      {project.techList.map((el, idx) => (
                        <span className="skill-item" key={idx}>
                          {el}
                        </span>
                      ))}
                    </div>

                    <div className="project-description">
                      {lang && (
                        <p
                          ref={boxRef}
                          dangerouslySetInnerHTML={{
                            __html:
                              lang === "fr"
                                ? project.descriptionFR
                                : project.descriptionEN,
                          }}
                        ></p>
                      )}
                    </div>
                  </div>
                  <div className="gallery">
                    {project.image.map((el, index) => (
                      <button
                        onClick={(e) => openLightboxOnSlide(index + 1)}
                        key={index}
                      >
                        <img src={project.image[index]} alt={project.title} />
                      </button>
                    ))}

                    <FsLightbox
                      sources={project.image}
                      type="image"
                      slide={lightboxController.slide}
                      toggler={lightboxController.toggler}
                    />
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
