import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  1024: { items: 1 },
};

const onInitialized = (e) => {
  console.debug(`Start position(activeIndex) on init: ${e.item}. Event:`, e);
};

const onSlideChange = (e) => {
  console.debug(`Item's position before a change: ${e.item}. Event:`, e);
};

const onSlideChanged = (e) => {
  console.debug(`Item's position after changes: ${e.item}. Event:`, e);
};

const onResized = (e) => {
  console.debug(`Item's position after resize: ${e.item}. Event:`, e);
};

export default function CarouselAlice(props) {
  return (
    <AliceCarousel
      mouseTracking
      infinite={true}
      keyboardNavigation
      items={props.items}
      responsive={responsive}
      onInitialized={onInitialized}
      onSlideChange={onSlideChange}
      onSlideChanged={onSlideChanged}
      onResized={onResized}
      autoPlay={true}
      autoPlayInterval={2000}
    />
  );
}
